import React from "react"
import RacepageNew from "../components/racepageNew"

const Kuurne2021 = () => {
  const startlist = [
    {
      number: " 247",
      name: " VAN GENECHTEN Jonas",
      team: " B&B Hotels",
    },
    {
      number: " 246",
      name: " MORICE Julien",
      team: " B&B Hotels",
    },
    {
      number: " 245",
      name: " LEMOINE Cyril",
      team: " B&B Hotels",
    },
    {
      number: " 244",
      name: " LECROQ Jérémy",
      team: " B&B Hotels",
    },
    {
      number: " 243",
      name: " DEBUSSCHERE Jens",
      team: " B&B Hotels",
    },
    {
      number: " 242",
      name: " DE BACKER Bert",
      team: " B&B Hotels",
    },
    {
      number: " 241",
      name: " COQUARD Bryan",
      team: " B&B Hotels",
    },
    {
      number: " 237",
      name: " WEEMAES Sasha",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      number: " 236",
      name: " VANHOOF Ward",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      number: " 235",
      name: " VAN DEN BOSSCHE Fabio",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      number: " 234",
      name: " REYNDERS Jens",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      number: " 233",
      name: " MARIT Arne",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      number: " 232",
      name: " DE WILDE Gilles",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      number: " 231",
      name: " DE VYLDER Lindsay",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      number: " 227",
      name: " VALLÉE Boris",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      number: " 226",
      name: " REX Laurenz",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      number: " 225",
      name: " PAQUOT Tom",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      number: " 224",
      name: " LIVYNS Arjen",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      number: " 223",
      name: " DUPONT Timothy",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      number: " 222",
      name: " SUTER Joel",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      number: " 221",
      name: " ANIOŁKOWSKI Stanisław",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      number: " 217",
      name: " VAN GESTEL Dries",
      team: " Team Total Direct Energie",
    },
    {
      number: " 216",
      name: " TURGIS Anthony",
      team: " Team Total Direct Energie",
    },
    {
      number: " 215",
      name: " TERPSTRA Niki",
      team: " Team Total Direct Energie",
    },
    {
      number: " 214",
      name: " SOUPE Geoffrey",
      team: " Team Total Direct Energie",
    },
    {
      number: " 213",
      name: " MANZIN Lorrenzo",
      team: " Team Total Direct Energie",
    },
    {
      number: " 212",
      name: " BONIFAZIO Niccolò",
      team: " Team Total Direct Energie",
    },
    {
      number: " 211",
      name: " BOASSON HAGEN Edvald",
      team: " Team Total Direct Energie",
    },
    {
      number: " 207",
      name: " SKAARSETH Anders",
      team: " Uno-X Pro Cycling Team",
    },
    {
      number: " 206",
      name: " HALVORSEN Kristoffer",
      team: " Uno-X Pro Cycling Team",
    },
    {
      number: " 205",
      name: " HVIDEBERG Jonas Iversby",
      team: " Uno-X Pro Cycling Team",
    },
    {
      number: " 204",
      name: " RESELL Erik Nordsaeter",
      team: " Uno-X Pro Cycling Team",
    },
    {
      number: " 203",
      name: " TILLER Rasmus",
      team: " Uno-X Pro Cycling Team",
    },
    {
      number: " 202",
      name: " HOELGAARD Markus",
      team: " Uno-X Pro Cycling Team",
    },
    {
      number: " 201",
      name: " WÆRENSKJOLD Søren",
      team: " Uno-X Pro Cycling Team",
    },
    {
      number: " 197",
      name: " ORRICO Davide",
      team: " Vini Zabù",
    },
    {
      number: " 196",
      name: " BEVILACQUA Simone",
      team: " Vini Zabù",
    },
    {
      number: " 195",
      name: " VAN EMPEL Etienne",
      team: " Vini Zabù",
    },
    {
      number: " 194",
      name: " FRAPPORTI Marco",
      team: " Vini Zabù",
    },
    {
      number: " 193",
      name: " SCHNEITER Joab",
      team: " Vini Zabù",
    },
    {
      number: " 192",
      name: " GRADEK Kamil",
      team: " Vini Zabù",
    },
    {
      number: " 191",
      name: " MARECZKO Jakub",
      team: " Vini Zabù",
    },
    {
      number: " 187",
      name: " WELTEN Bram",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 186",
      name: " SWIFT Connor",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 185",
      name: " RUSSO Clément",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 184",
      name: " MCLAY Daniel",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 183",
      name: " PAJUR Markus",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 182",
      name: " CAPIOT Amaury",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 181",
      name: " BOUDAT Thomas",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 177",
      name: " MERLIER Tim",
      team: " Alpecin-Fenix",
    },
    {
      number: " 176",
      name: " TAMINIAUX Lionel",
      team: " Alpecin-Fenix",
    },
    {
      number: " 175",
      name: " RIESEBEEK Oscar",
      team: " Alpecin-Fenix",
    },
    {
      number: " 174",
      name: " PLANCKAERT Edward",
      team: " Alpecin-Fenix",
    },
    {
      number: " 173",
      name: " DILLIER Silvan",
      team: " Alpecin-Fenix",
    },
    {
      number: " 172",
      name: " RICKAERT Jonas",
      team: " Alpecin-Fenix",
    },
    {
      number: " 171",
      name: " VAN DER POEL Mathieu",
      team: " Alpecin-Fenix",
    },
    {
      number: " 167",
      name: " LEKNESSUND Andreas",
      team: " Team DSM",
    },
    {
      number: " 166",
      name: " KANTER Max",
      team: " Team DSM",
    },
    {
      number: " 165",
      name: " EEKHOFF Nils",
      team: " Team DSM",
    },
    {
      number: " 164",
      name: " PEDERSEN Casper",
      team: " Team DSM",
    },
    {
      number: " 163",
      name: " BRENNER Marco",
      team: " Team DSM",
    },
    {
      number: " 162",
      name: " BENOOT Tiesj",
      team: " Team DSM",
    },
    {
      number: " 161",
      name: " KRAGH ANDERSEN Søren",
      team: " Team DSM",
    },
    {
      number: " 157",
      name: " JUUL-JENSEN Christopher",
      team: " Team BikeExchange",
    },
    {
      number: " 156",
      name: " JANSEN Amund Grøndahl",
      team: " Team BikeExchange",
    },
    {
      number: " 155",
      name: " SCOTSON Callum",
      team: " Team BikeExchange",
    },
    {
      number: " 154",
      name: " STANNARD Robert",
      team: " Team BikeExchange",
    },
    {
      number: " 153",
      name: " SMITH Dion",
      team: " Team BikeExchange",
    },
    {
      number: " 152",
      name: " EDMONDSON Alex",
      team: " Team BikeExchange",
    },
    {
      number: " 151",
      name: " DURBRIDGE Luke",
      team: " Team BikeExchange",
    },
    {
      number: " 147",
      name: " PERRY Benjamin",
      team: " Astana - Premier Tech",
    },
    {
      number: " 146",
      name: " MARTINELLI Davide",
      team: " Astana - Premier Tech",
    },
    {
      number: " 145",
      name: " IZAGIRRE Gorka",
      team: " Astana - Premier Tech",
    },
    {
      number: " 144",
      name: " FELLINE Fabio",
      team: " Astana - Premier Tech",
    },
    {
      number: " 143",
      name: " ZAKHAROV Artyom",
      team: " Astana - Premier Tech",
    },
    {
      number: " 142",
      name: " BOARO Manuele",
      team: " Astana - Premier Tech",
    },
    {
      number: " 141",
      name: " ARANBURU Alex",
      team: " Astana - Premier Tech",
    },
    {
      number: " 137",
      name: " WALLAYS Jelle",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 136",
      name: " CARVALHO Andre",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 135",
      name: " SAJNOK Szymon",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 134",
      name: " DRUCKER Jempy",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 133",
      name: " BOHLI Tom",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 132",
      name: " ALLEGAERT Piet",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 131",
      name: " LAPORTE Christophe",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 127",
      name: " WILLIAMS Stephen",
      team: " Bahrain - Victorious",
    },
    {
      number: " 126",
      name: " TEUNS Dylan",
      team: " Bahrain - Victorious",
    },
    {
      number: " 125",
      name: " SIEBERG Marcel",
      team: " Bahrain - Victorious",
    },
    {
      number: " 124",
      name: " HAUSSLER Heinrich",
      team: " Bahrain - Victorious",
    },
    {
      number: " 123",
      name: " HALLER Marco",
      team: " Bahrain - Victorious",
    },
    {
      number: " 122",
      name: " PADUN Mark",
      team: " Bahrain - Victorious",
    },
    {
      number: " 121",
      name: " COLBRELLI Sonny",
      team: " Bahrain - Victorious",
    },
    {
      number: " 117",
      name: " WIŚNIOWSKI Łukasz",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 116",
      name: " STOKBRO Andreas",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 115",
      name: " BARBERO Carlos",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 114",
      name: " GOGL Michael",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 113",
      name: " CLAEYS Dimitri",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 112",
      name: " CAMPENAERTS Victor",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 111",
      name: " ARMÉE Sander",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 107",
      name: " VANSPEYBROUCK Pieter",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 106",
      name: " VAN POPPEL Danny",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 105",
      name: " VAN POPPEL Boy",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 104",
      name: " PASQUALON Andrea",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 103",
      name: " VAN DER HOORN Taco",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 102",
      name: " DEVRIENDT Tom",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 101",
      name: " DE WINTER Ludwig",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 97",
      name: " VAN DEN BERG Julius",
      team: " EF Education - Nippo",
    },
    {
      number: " 96",
      name: " DOCKER Mitchell",
      team: " EF Education - Nippo",
    },
    {
      number: " 95",
      name: " SCULLY Tom",
      team: " EF Education - Nippo",
    },
    {
      number: " 94",
      name: " RUTSCH Jonas",
      team: " EF Education - Nippo",
    },
    {
      number: " 93",
      name: " LANGEVELD Sebastian",
      team: " EF Education - Nippo",
    },
    {
      number: " 92",
      name: " KEUKELEIRE Jens",
      team: " EF Education - Nippo",
    },
    {
      number: " 91",
      name: " HOFLAND Moreno",
      team: " EF Education - Nippo",
    },
    {
      number: " 87",
      name: " TRENTIN Matteo",
      team: " UAE-Team Emirates",
    },
    {
      number: " 86",
      name: " TROIA Oliviero",
      team: " UAE-Team Emirates",
    },
    {
      number: " 85",
      name: " LAENGEN Vegard Stake",
      team: " UAE-Team Emirates",
    },
    {
      number: " 84",
      name: " MARCATO Marco",
      team: " UAE-Team Emirates",
    },
    {
      number: " 83",
      name: " GIBBONS Ryan",
      team: " UAE-Team Emirates",
    },
    {
      number: " 82",
      name: " BYSTRØM Sven Erik",
      team: " UAE-Team Emirates",
    },
    {
      number: " 81",
      name: " KRISTOFF Alexander",
      team: " UAE-Team Emirates",
    },
    {
      number: " 77",
      name: " SINKELDAM Ramon",
      team: " Groupama - FDJ",
    },
    {
      number: " 76",
      name: " LUDVIGSSON Tobias",
      team: " Groupama - FDJ",
    },
    {
      number: " 75",
      name: " LIENHARD Fabian",
      team: " Groupama - FDJ",
    },
    {
      number: " 74",
      name: " SCOTSON Miles",
      team: " Groupama - FDJ",
    },
    {
      number: " 73",
      name: " KÜNG Stefan",
      team: " Groupama - FDJ",
    },
    {
      number: " 72",
      name: " GENIETS Kevin",
      team: " Groupama - FDJ",
    },
    {
      number: " 71",
      name: " DÉMARE Arnaud",
      team: " Groupama - FDJ",
    },
    {
      number: " 67",
      name: " VAN ASBROECK Tom",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 66",
      name: " WÜRTZ SCHMIDT Mads",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 65",
      name: " HOFSTETTER Hugo",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 64",
      name: " VANMARCKE Sep",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 63",
      name: " BOIVIN Guillaume",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 62",
      name: " BIERMANS Jenthe",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 61",
      name: " BARBIER Rudy",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 57",
      name: " OLDANI Stefano",
      team: " Lotto Soudal",
    },
    {
      number: " 56",
      name: " KRON Andreas",
      team: " Lotto Soudal",
    },
    {
      number: " 55",
      name: " THIJSSEN Gerben",
      team: " Lotto Soudal",
    },
    {
      number: " 54",
      name: " FRISON Frederik",
      team: " Lotto Soudal",
    },
    {
      number: " 53",
      name: " VERMEERSCH Florian",
      team: " Lotto Soudal",
    },
    {
      number: " 52",
      name: " WELLENS Tim",
      team: " Lotto Soudal",
    },
    {
      number: " 51",
      name: " DEGENKOLB John",
      team: " Lotto Soudal",
    },
    {
      number: " 47",
      name: " GOŁAŚ Michał",
      team: " INEOS Grenadiers",
    },
    {
      number: " 46",
      name: " PIDCOCK Thomas",
      team: " INEOS Grenadiers",
    },
    {
      number: " 45",
      name: " NARVÁEZ Jhonatan",
      team: " INEOS Grenadiers",
    },
    {
      number: " 44",
      name: " MOSCON Gianni",
      team: " INEOS Grenadiers",
    },
    {
      number: " 43",
      name: " HAYTER Ethan",
      team: " INEOS Grenadiers",
    },
    {
      number: " 42",
      name: " DOULL Owain",
      team: " INEOS Grenadiers",
    },
    {
      number: " 41",
      name: " BASSO Leonardo",
      team: " INEOS Grenadiers",
    },
    {
      number: " 37",
      name: " POLITT Nils",
      team: " BORA - hansgrohe",
    },
    {
      number: " 36",
      name: " PÖSTLBERGER Lukas",
      team: " BORA - hansgrohe",
    },
    {
      number: " 35",
      name: " OSS Daniel",
      team: " BORA - hansgrohe",
    },
    {
      number: " 34",
      name: " MEEUS Jordi",
      team: " BORA - hansgrohe",
    },
    {
      number: " 33",
      name: " GAMPER Patrick",
      team: " BORA - hansgrohe",
    },
    {
      number: " 32",
      name: " SCHELLING Ide",
      team: " BORA - hansgrohe",
    },
    {
      number: " 31",
      name: " BODNAR Maciej",
      team: " BORA - hansgrohe",
    },
    {
      number: " 27",
      name: " VAN HOECKE Gijs",
      team: " AG2R Citroën Team",
    },
    {
      number: " 26",
      name: " JULLIEN Anthony",
      team: " AG2R Citroën Team",
    },
    {
      number: " 25",
      name: " SARREAU Marc",
      team: " AG2R Citroën Team",
    },
    {
      number: " 24",
      name: " NAESEN Lawrence",
      team: " AG2R Citroën Team",
    },
    {
      number: " 23",
      name: " DUVAL Julien",
      team: " AG2R Citroën Team",
    },
    {
      number: " 22",
      name: " NAESEN Oliver",
      team: " AG2R Citroën Team",
    },
    {
      number: " 21",
      name: " VAN AVERMAET Greg",
      team: " AG2R Citroën Team",
    },
    {
      number: " 17",
      name: " MULLEN Ryan",
      team: " Trek - Segafredo",
    },
    {
      number: " 16",
      name: " THEUNS Edward",
      team: " Trek - Segafredo",
    },
    {
      number: " 14",
      name: " EGHOLM Jakob",
      team: " Trek - Segafredo",
    },
    {
      number: " 13",
      name: " KIRSCH Alex",
      team: " Trek - Segafredo",
    },
    {
      number: " 12",
      name: " PEDERSEN Mads",
      team: " Trek - Segafredo",
    },
    {
      number: " 11",
      name: " STUYVEN Jasper",
      team: " Trek - Segafredo",
    },
    {
      number: " 7",
      name: " VAN LERBERGHE Bert",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 6",
      name: " ŠTYBAR Zdeněk",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 5",
      name: " STEIMLE Jannik",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 4",
      name: " LAMPAERT Yves",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 3",
      name: " STEELS Stijn",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 2",
      name: " HODEG Álvaro José",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 1",
      name: " ASGREEN Kasper",
      team: " Deceuninck - Quick Step",
    },
  ]

  const race = "Kuurne"

  return <RacepageNew title="Kuurne 2021" startlist={startlist} race={race} />
}

export default Kuurne2021
