import React, { useState, useEffect } from "react"
import "../style/style.css"
import { useTransition, animated } from "react-spring"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import Header from "./header"
import useKeyPress from "./useKeyPress"

function RacepageNew({ title, startlist, race }) {
  const [currentNumber, setCurrentNumber] = useState("1")
  const [datetimeLastPress, setDatetimeLastPress] = useState(Date.now())

  const handleNumPad = ev => {
    const addedNumber = ev.target.value.toString()
    const timeDifference = Date.now() - datetimeLastPress

    if (currentNumber.length < 3 && timeDifference < 4000) {
      setCurrentNumber(currentNumber.concat(addedNumber))
      setDatetimeLastPress(Date.now())
    } else {
      setCurrentNumber(addedNumber)
      setDatetimeLastPress(Date.now())
    }
  }

  const handleBackspace = () => {
    if (currentNumber.length > 0) {
      setCurrentNumber(currentNumber.slice(0, -1))
      setDatetimeLastPress(Date.now())
    }
  }

  // Handle keyboard events
  const lastKeyPressed = useKeyPress()

  useEffect(() => {
    const addedNumber = lastKeyPressed
    const acceptableKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
    ]
    if (
      addedNumber != null &&
      addedNumber != "Backspace" &&
      acceptableKeys.includes(addedNumber)
    ) {
      const timeDifference = Date.now() - datetimeLastPress

      if (currentNumber && currentNumber.length < 3 && timeDifference < 4000) {
        setCurrentNumber(currentNumber.concat(addedNumber))
        setDatetimeLastPress(Date.now())
      } else {
        setCurrentNumber(addedNumber)
        setDatetimeLastPress(Date.now())
      }
    } else if (addedNumber == "Backspace") {
      handleBackspace()
    }
  }, [lastKeyPressed])

  // Toggle between startlist and numpad view
  const [viewMode, setViewMode] = useState("numPad")

  const transition = useTransition(currentNumber, {
    from: { opacity: 0, filter: "blur(1rem)" },
    enter: { opacity: 1, filter: "blur(0rem)" },
    config: { tension: 150, clamp: true },
  })

  return (
    <div className="App">
      <div className="container">
        <Header title={title} race={race} />
        <div className="currentRider">
          {startlist.find(rider => rider.number === ` ${currentNumber}`) && (
            <>
              {transition((style, item) => {
                const rider = startlist.find(
                  rider => rider.number === ` ${currentNumber}`
                )
                return (
                  <animated.div style={style}>
                    <h2>{rider.name}</h2>
                    <h3>{rider.team}</h3>
                    {/* <OutboundLink href={`https://procyclingstats.com/`}>
                      Check rider on PCS
                    </OutboundLink> */}
                  </animated.div>
                )
              })}
            </>
          )}
          {!startlist.find(rider => rider.number === ` ${currentNumber}`) && (
            <div>
              <img alt="riderIcon" src={require("../style/rider.png")} />
            </div>
          )}
        </div>
        {viewMode === "numPad" && (
          <>
            <div
              className="currentNumber"
              style={{
                backgroundColor: `${
                  startlist[currentNumber] ? "#cff1c5" : "#c5d4f1"
                }`,
              }}
            >
              {(currentNumber && `#${currentNumber}`) ||
                `Enter a rider's number`}
              <span
                style={{ display: `${currentNumber ? "initial" : "none"}` }}
                className="clearNumberButton"
                onClick={() => setCurrentNumber("")}
              >
                clear
              </span>
            </div>

            <div className="numPad">
              <button
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="1"
              >
                1
              </button>
              <button
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="2"
              >
                2
              </button>
              <button
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="3"
              >
                3
              </button>
              <button
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="4"
              >
                4
              </button>
              <button
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="5"
              >
                5
              </button>
              <button
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="6"
              >
                6
              </button>
              <button
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="7"
              >
                7
              </button>
              <button
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="8"
              >
                8
              </button>
              <button
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="9"
              >
                9
              </button>
              <button
                style={{ gridColumn: `1 / span 2` }}
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="0"
              >
                0
              </button>
              <button
                className="backspace"
                onClick={() => {
                  handleBackspace()
                }}
              >
                <img
                  alt="backspaceIcon"
                  src={require("../style/backspace.png")}
                />
              </button>
            </div>
          </>
        )}
        {viewMode === "startList" && (
          <div className="startListContainer">
            <div className="startList">
              {startlist
                .slice(0) // Slice to make shallow copy to prevent continuously reversing original array
                .reverse()
                .map((rider, index) => {
                  // Show only the team name before each first rider of a team
                  // Change to IF last char is 1 -> show teamname
                  let showTeamName = false
                  const nr = rider.number // Remember that PCS data generates a space before the first character
                  const intNr = nr.substring(1)
                  if (nr.includes("1") && nr.length == 2) {
                    showTeamName = true
                  } else if (
                    nr.includes("1") &&
                    nr.charAt(1) != "1" &&
                    nr.charAt(2) == "1" &&
                    nr.length == 3
                  ) {
                    showTeamName = true
                  } else if (
                    nr.includes("1") &&
                    nr.charAt(1) == "1" &&
                    nr.charAt(2) == "1" &&
                    nr.length == 3
                  ) {
                    showTeamName = true
                  } else if (
                    nr.includes("1") &&
                    nr.charAt(1) == "1" &&
                    nr.charAt(3) == "1"
                  ) {
                    showTeamName = true
                  } else if (
                    nr.includes("1") &&
                    nr.charAt(1) == "2" &&
                    nr.charAt(3) == "1"
                  ) {
                    showTeamName = true
                  }
                  return (
                    <>
                      {showTeamName && (
                        <div className="listTeamName">{rider.team}</div>
                      )}
                      <div
                        key={index}
                        className={`listRider ${
                          currentNumber == intNr ? "listActiveRider" : ""
                        }`}
                        onClick={() => {
                          setCurrentNumber(intNr)
                        }}
                      >
                        <span className="listRiderNumber">{intNr}</span>
                        <span
                          style={{
                            fontWeight: `${showTeamName ? "bold" : ""}`,
                          }}
                        >
                          {rider.name}
                        </span>
                      </div>
                    </>
                  )
                })}
            </div>
          </div>
        )}
        <div className="toggleContainer">
          <div
            className={`toggleItem ${
              viewMode === "numPad" ? "activeItem" : ""
            }`}
            onClick={() => {
              setViewMode("numPad")
            }}
          >
            <span>Track by Number</span>
          </div>
          <div
            className={`toggleItem ${
              viewMode === "startList" ? "activeItem" : ""
            }`}
            onClick={() => {
              setViewMode("startList")
            }}
          >
            <span>Startlist</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RacepageNew
